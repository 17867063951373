.overlay-wrapper {
  position: relative;
}

.overlay {
  flex-direction: column;
}

.overlay.dark {
  color: #fff;
}
