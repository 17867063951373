.news-category-item {
    background: #EEE;
    padding: 3px 10px;
    border: 1px solid #CCC;
    border-radius: 5px;
}

.news-category-item.category-enabled {
    background: #EFE;
}

.news-category-item.category-disabled {
    background: #FEE;
}