button.nav-link {
    border: none;
    background: transparent;
}

.overlay.loader {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
