.page-item:not(.disabled) .page-link {
    cursor: pointer;
}

select.per-page-dropdown {
    display: inline;
    width: auto;
    height: calc(2rem + 2px);
    padding: 0 5px;
}
